/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body {
  background-color: #000000;
  overflow-y: auto;
  height: 100vh;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-mabry {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Atlas Grotesk';
  src: url('/src/fonts/atlas/AtlasGrotesk-Thin-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Atlas Grotesk';
  src: url('/src/fonts/atlas/AtlasGrotesk-Bold-Gr-Trial.otf') format('opentype');
  font-weight: bold; 
  font-style: normal;
}

@font-face {
  font-family: 'Atlas Grotesk';
  src: url('/src/fonts/atlas/AtlasGrotesk-Regular-Trial.otf') format('opentype');
  font-weight: medium; 
  font-style: normal;
}

@font-face {
  font-family: 'Mabry Trail';
  src: url('/src/fonts/mabry/mabry-black-trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry Trail light';
  src: url('/src/fonts/mabry/mabry-light-trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry Trail medium';
  src: url('/src/fonts/mabry/mabry-medium-trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



/* 

  Sitewide changes

*/

/* Base styles */
h1 {
  font-family: 'Mabry Trail', sans-serif;
  color: white;
  font-size: 6vw; /* Adjusted for responsiveness */
}

p {
  color: #FFF;
  font-family: "Atlas Grotesk";
  font-size: 1.5vw; /* Adjusted for responsiveness */
}

h1, h2, h3, h4, h5, a, p {
  margin: 0px;
}


/* Larger devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 100px; /* Fixed size for larger screens */
  }

  p {
    font-size: 30px; /* Fixed size for larger screens */
  }
}

/* Smaller devices (tablets, 768px and up) */
@media (max-width: 767px) {
  h1 {
    font-size: 5vw; /* Smaller size for tablets */
  }

  p {
    font-size: 3vw; /* Smaller size for tablets */
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  h1 {
    font-size: 8vw; /* Even smaller size for phones */
  }

  p {
    font-size: 4vw; /* Even smaller size for phones */
  }
}


.background {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0; 
}

.swiper-container {
  position: relative;
  z-index: 1;
}


/* 

  Introduction page

*/


.introduction-container {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.introduction-container h1{
  color: #F2C14E
}

.introduction-container p{
  font-family: 'Atlas Grotesk';
  font-size: 14px;
  font-weight: 500;
  color: #C7C7C7;
}


.buttons-container {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
  padding-top: 40px;
}


a.upload-button{
  border-radius: 10px;
  color:black;
  font-weight: 700;
  font-size: 18px;
  background-color: #F2C14E;
  box-shadow: 0px 0px 35px 15px rgba(242, 193, 78, 0.40);
  padding: 17px 60px;
}

.upload-button{
  border-radius: 10px;
  color:black;
  font-weight: 700;
  font-size: 18px;
  background-color: #F2C14E;
  box-shadow: 0px 0px 35px 15px rgba(242, 193, 78, 0.40);
  padding: 17px 60px;
}

a.upload-button:hover{
  background-color: #f6bb31;
  box-shadow: 0px 0px 35px 15px rgba(241, 191, 72, 0.4);
}

.upload-button:hover{
  background-color: #f6bb31;
  box-shadow: 0px 0px 35px 15px rgba(241, 191, 72, 0.4);
}

a.later-button{
  color: #FFFFFF;
  background: none!important;
  margin: auto;
}


a  {
  padding: 10px 20px;
  border: none;
  background-color: #0070f3;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
}



/* 

  Background

*/

.background {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Create 3 columns */
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden;
}

.column {
  width: 100%;
}

.artwork-card {
  width: 200px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  margin: 20px;
}

.artwork-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
}


@keyframes animatedUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes animatedDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.column.move-up .artwork-card {
  animation: animatedUp 60s linear infinite;
}

.column.move-down .artwork-card {
  animation: animatedDown 60s linear infinite;
}



/* 

  Drag and drop upload page

*/


div.drag-upload-box {
  background-color: #000000;
  max-width: 700px;
  padding: 90px 40px;
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0px 0px 35px 15px rgba(242, 188, 64, 0.4);
  border: 3px dotted #F2C14E; 
  cursor: pointer;

  /* due to the svg remove padding top */
  /* padding-top: 0px;  */
}


/* div.drag-upload-box.uploading {
  background: #37363647;
  color: #f2c14e8c;
  border: 1px solid #37363647; 
  box-shadow: 0px 0px 35px 15px rgba(242, 189, 64, 0.277);
}

div.drag-upload-box.uploading:hover{
  background: #37363647;
}

div.drag-upload-box.uploading:hover h1{
  color: #f2c14ec6;
}


div.drag-upload-box.uploading h1{
  color: #f2c14ec6;
} */

div.drag-upload-box:hover{
  background-color: #F2C14E;
}

div.drag-upload-box:hover p.error{
  color: black
}

div.drag-upload-box svg {
  margin: 30px;
}


div.drag-upload-box:hover svg path{
  fill: #000000;
}

div.drag-upload-box:hover h1{
  color: #000000;
}


div.upload-options {
  display: grid;
  margin-top: 50px;
}

div.upload-options span{
  color: #F2C14E;
  margin: 10px 0px;
}

.drag-upload-box h1 {
  font-size: 50px;
  font-weight: 200;
}

.drag-upload-box h1 b{
  font-size: 50px;
  font-weight: 500;
}


@media (max-width: 640px) { /* Tailwind's default breakpoint for 'sm' */
  div.upload-options{
    display: none;
  }

  div.drag-upload-box h1 {
    font-size: 25px;
  }

  div.drag-upload-box h1 b{
    font-size: 25px;
  }

  div.drag-upload-box {
    margin: 0px 40px;
  }

  .application-form-container button{
    width: 80vw!important;
    margin: auto;
    margin-top: 15px;
  }

  .application-form-container label{
    width: 80vw!important;
    margin: auto;
  }

  div.absolute.navbar-menu{
    width: 80vw!important;
  }
}


div.image-preview img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 8px;
}

/* div.image-preview {
  width: 400px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
  margin: 0px 20px;
  width: 400px;
  height: 250px;
} */

div.image-preview {
  width: 40vw;
  border-radius: 8px;
  overflow: hidden;
  background-color: transparent;
  /* margin: 0px 20px; */
}

/* 

  Confirm images

*/

div.upload-confirmation div.image-preview img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

div.upload-confirmation div.image-preview {
  width: 600px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
  margin: 0px 20px;
  /* width: 400px;
  height: 250px; */
}

/* div.image-preview img.current {
  box-shadow: 0px 0px 35px 15px rgba(242, 188, 64, 0.4);
} */

.upload-confirmation h1{
  font-size: 40px;
}

.image-preview-container{
  display: inline-flex;
}

div.upload-confirmation div {
  opacity: 1!important;
}

.introduction-container .upload-confirmation .content{
  width: 50vw;
}


/* input[type="text"], input[type="password"] {
  background-color: rgb(47, 46, 46);
  padding: 15px 20px;
  border-radius: 8px;
  border: none; 
  outline: none; 
  box-shadow: none; 
  color: #fff;
}

input[type="text"]::placeholder, input[type="password"]::placeholder {
  color: #ddd; 
}

input[type="text"]:focus, input[type="password"]:focus {
  box-shadow: none;
} */

.upload-confirmation input{
  margin: 10px 0px;
  width: 300px;
}

form {
  display: inline-block;
}

.nav-options-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px; /* Adjust the value as needed */
  padding-right: 20px; /* Adjust the value as needed */
  width: 100%; /* Ensures the div takes the full width available */
}

.introduction-container.upload-confirmation.upload-details{
  display: flex;
  flex-direction: column;
  justify-content: space-around; 
  height: 80vh; 
}

.introduction-container.upload-confirmation{
  display: flex;
  flex-direction: column;
  justify-content: space-around; 
  height: 80vh; 
}


.nav-options-select h1{
  font-size: 40px;
}

.nav-options-select > div {
  margin: 0 5vw; 
}

.nav-options-select svg{
  fill: #F2C14E;
}

.nav-options-select a.later-button{
  color: #F2C14E;
  font-weight: 700;
}


/* 

Login

*/

.main-container {
  position: relative;
  min-height: 100vh;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.card {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  max-width: 350px;
  width: 100%;
  backdrop-filter: blur(15px);
}

.card h2 {
  margin-bottom: 25px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  width: 250px;
  border: 1px solid none;
  box-sizing: border-box;
}

.form-group button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: #F2C14E;
  box-shadow: 0px 0px 35px 15px rgba(239, 183, 53, 0.4);
  color: white;
  cursor: pointer;
  font-size: 16px;

  max-width: 200px;
  font-weight: 600;
  margin-top: 40px;
}

.form-group button:hover {
  background-color: #eba92e;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.login-container p{
  font-size: 11px;
  margin-top: 20px;
}

.login-container a{
  all: unset;
  font-size: 11px;
  cursor: pointer;
  color: #eba92e;
}

.error-message {
  color: red;
  margin-top: 5px;
}

h3 {
  color: rgb(173, 173, 173);
}


/* .artwork-card {
  width: 200px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  margin: 20px;
}

.artwork-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
} */




/* 

Form 

*/

main{
  background-color: #1e1e1e6e;
  backdrop-filter: blur(15px);
}

div.custom-field {
  margin: auto 20px;
  max-width: 600px;
}

.custom-field.otp {
  display: inline-flex;
}

.custom-field.otp input{
  margin: 10px;
}

.custom-field label {
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.custom-field input, .custom-field textarea {
  background-color: #3c3c3cb9;
  border-radius: 8px;
  padding: 15px 20px;
  color: #FFFFFF;
  border: unset;
  outline: none;
}



.custom-field input.loading {
  background-color: #252525b9;
}

.custom-field input:focus, .custom-field textarea:focus{
  background-color: #202020b5;
  backdrop-filter: blur(6px);
}

.custom-field input:disabled, .custom-field textarea:disabled{
  background-color: #3c3c3c7a;
  color: rgb(104, 100, 100);
}

.custom-field input::placeholder, .custom-field textarea::placeholder {
  color: #929292;
  letter-spacing: -0.40px;
  opacity: 1;
}

.custom-field input:focus {
  box-shadow: inset 0 0 0 1px #F2C14E; 
  box-sizing: border-box;
  -webkit-appearance: none;
}


.custom-field.combo-input input.combo:focus{
  box-shadow: 
    1px 0 0 0 #F2C14E,
    0 1px 0 0 #F2C14E,
    0 -1px 0 0 #F2C14E; 
  box-sizing: border-box;
  -webkit-appearance: none;
}


.custom-field.combo-input input:focus + svg {
  color: #F2C14E;
}

.custom-field input:focus + svg {
  color: #F2C14E;
}

.custom-field input + svg {
  color: #969191;
}

.custom-field.combo-input button:disabled{
  background-color: #3c3c3c7a!important;
  color: #929292!important;
}

div.custom-field div[role='radiogroup'] span{
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

a.custom-field-advanced{
  background-color: transparent;
  padding-top: 0px;
  margin-right: auto;
  margin-left: 5px;
  font-weight: 400;
  height: auto;
  color: #8B8989;
}

a.custom-field-advanced span:hover{
  color: #a8a5a5;
}

/* Disabled button */

a.custom-field-advanced:disabled {
  color: #a8a5a578!important;
}

a.custom-field-advanced:disabled span:hover{
  color: #a8a5a578!important;
}


.profile-widget span{
  color: #F2C14E;
  /* background-color:#000000; */
}

.profile-widget span.text-tiny{
  color: #A1A1AA;
}

div.main-artwork-carousel{
  background-color: transparent;
}

@media (min-width: 768px) {
  div.main-artwork-carousel {
    background-color: #36362F; /* Background color for medium screens and up */
  }
}



/* 

Carosel component

*/

.image-with-glow {
  position: relative;
  display: inline-block; /* Or 'block' depending on your layout */
  /* overflow: hidden; */
  border-radius: 8px; /* Optional: for rounded corners */
}

.image-with-glow__background {
  position: absolute;
  top: -10px; /* Adjust for desired glow spread */
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-size: cover;
  filter: blur(30px); /* Adjust blur radius for desired glow effect */
  opacity: 1;
  z-index: 2;
}

.image-with-glow__foreground {
  position: relative;
  width: auto; /* Adjust to match the container's size */
  height: 50vh;
  object-fit: cover; /* Adjust as needed */
  z-index: 2;
}


@media (max-width: 768px) {
  .image-with-glow__foreground {
    height: 20vh;
    margin: 20px;
  }
}

/* ImageCarouselScroll.module.css or ImageCarouselScroll.css */
.carouselContainer {
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.imageWrapper {
  width: 100%;
  height: 80%;
  margin-bottom: 20px;
}

@media (max-width: 640px) { /* Tailwind's default breakpoint for 'sm' */

  .custom-field input:focus, .custom-field textarea:focus{
    background-color: #202020b5;
    backdrop-filter: blur(0px);
  }
  

  div.login-component form.space-y-6 {
    width: 85vw;
  }

  div.login-component .secondaryButton {

  }

  div.login-component .custom-field.otp input{
    width: 40px;
    height: 50px;
    padding: 5px 15px;
  }

  div.login-component .custom-field.otp{
    display: inline-flex;
    width: unset;

  }

  .imageWrapper{
    padding-top: 50px;
  }

  .imageWrapper img {
    height: 40vh; /* Increase the height */
    width: auto;
    margin-bottom: 30px; /* Increase the bottom margin */
  }

  div.image-preview{
    width: 80vw;
  }
}

.displayedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.noImage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.thumbnailContainer {
  /* display: flex; */
  /* gap: 15px; */
}

.thumbnail {
  cursor: pointer;
  
}

.thumbnailImage {
  width: auto;
  min-width: 60px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: solid #ffffff48 1px;
  box-sizing: border-box; 
}

div.thumbnail.isThumbnailSelected .thumbnailImage {
  border: #f2c14eab 2px solid;
  border-radius: 8px;
  box-sizing: border-box;
  height: 105px;
}


button.secondaryButton {
  color: #F2C14E;
  padding: 15px 10px;
  margin-bottom: 20px;
  border: solid 2px #F2C14E;
  border-radius: 12px;
  /* width: 400px; */
  min-width: 400px;
}

button.secondaryButton:disabled{
  border: #ffffff1f 2px solid;
  color: #7B7B7B;
}

button.secondaryButton span{
  font-size: 14px;
  font-weight: 600;
  font-size: 1rem;
}

button.primaryButton{
  background-color: #F2C14E;
  border-radius: 12px;
  padding: 15px 20px;
  width: 100%;
}

button.primaryButton:hover{
  background-color: #f0b52e;
  cursor: pointer;
}

button.primaryButton span{
  font-family: 'inter';
  font-weight: 600;
}

button.primaryButton:disabled{
  background-color: #3838388e;
}

button.primaryButton:disabled:hover{
  background-color: #3838388e;
  cursor: pointer;
}

button.primaryButton:disabled span{
  font-family: 'inter';
  font-weight: 600;
  color: #ffffff51;
}

/* 

Audio record

*/

button.audio-record{
  background-color: #F2C14E;
  border-radius: 12px;
  padding: 15px 20px;
  width: 100%;
  box-shadow: 0 0 10px rgba(242, 193, 78, 0.8); 
}

button.audio-record:disabled, button.audio-record:disabled:hover{
  background-color: #9f8036;
  box-shadow: unset;
}

button.audio-record:hover{
  background-color: #f0b52e;
  cursor: pointer;
}

button.audio-record span{
  font-family: 'inter';
  font-weight: 600;
}

.spaceDivider {
  font-family: 'inter';
  color: #FFFFFF;
  font-weight: 600;
}



/* 

Custom toggle

*/

div.custom-toggle div.label-text.active {
  color: #F2C14E;
  opacity: 1;
}

div.custom-toggle div.label-text {
  color: #FFFFFF;
  opacity: 0.5;
}

div.custom-toggle div.bg-transparent-edges{
  background-color: transparent;
  border: #FFFFFF 1.5px solid;
}

div.custom-toggle div.bg-yellow{
  background-color: #F2C14E;
}

div.custom-field-center {
  margin: 0px auto;
}


.text-yellow{
  color: #F2C14E;
}


.pfp {
  /* border: solid 2px #f2c14e7d; */
  border-radius: 100%;
}

.pfp.borderd {
  border: solid 2px #f2c14e7d;
  /* border-radius: 100%; */
}



div[theme='dark'], div#root, html{
  background-color: #000000;
}

/* 
.custom-toggle {
  position: relative;
  width: 40px;
  height: 20px;
}

.toggle-background {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
}

.toggle-background-active {
  background-color: #3490dc;
}

.toggle-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-handle-active {@
  transform: translateX(20px);
  transform: translateY(-50%);
} */


/* .custom-field input[type='date']{
  display: block;
  line-height: normal;
  vertical-align: middle;
} */



span.small-introduction, a.small-introduction {
  background: unset;
  font-family: 'Mabry Trail';
  font-size: 14px;
  color: #F2C14E;
}


.blur-background {
  filter: blur(10px);
}




/* 

login-component

*/

/* .login-component h1{
  font-size: 20px;
  font-family: 'inter';
} */

/* .login-component p{
  font-size: 16px;
  color:#C7C7C7;
  margin: 10px 0px!important;
} */

.login-component p span{
  color: #F2C14E;
}

.login-component img{
  display: inline-flex;
}

.login-component h1.intro{
  font-weight: 700;
  font-size: 22px;
}

.login-component .custom-field{
  margin: 0px;
}

/* div.login-component .custom-field {
  display: inline;
  width: 300px;
} */



.mt-5{
  margin-top: 50px;
}
.mt-4{
  margin-top: 40px;
}
.mt-3{
  margin-top: 30px;
}
.mt-2{
  margin-top: 20px;
}
.mt-1{
  margin-top: 10px;
}

.pt-10{
  padding-top: 10px;

}

/* .swiper.swiper-ios{
  overflow-x: unset;
} */



.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


div.overlay-video {
  /* margin-top: -100px; */
  height: 100vh;
}


/* 

Application Form

*/

div.register-upload-options {
  display: grid;
}

div.register-upload-options span{
  color: #F2C14E;
  margin: 10px 0px;
}

div.register-upload-options label {
  border-radius: 10px;
  color:black;
  font-weight: 700;
  font-size: 18px;
  background-color: #F2C14E;
  box-shadow: 0px 0px 35px 15px rgba(242, 193, 78, 0.40);
  padding: 17px 60px;
}


div.register-upload-options div.image-preview{
  height: auto;
  margin-bottom: 20px;
  /* backdrop-filter: blur(4px);
  background-color: #22242561; */
}

div.register-upload-options div.image-preview img{
  border-radius: 8px;
  max-width: 100%;
  max-height: 200px;
  width: auto;
  margin: 0px auto;
}

button.primaryButton[type='submit']:not(:disabled) span{
  color: #1a1c21;
}

div.custom-field.flex-1{
  margin-top: 20px!important;
}



/* 

Custom tailwind calender

*/


.grid.grid-cols-7{
  display: grid;
  grid-template-columns: repeat(7, 1fr);

}

.grid.grid-cols-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: white!important;
}

.inline-block.rounded-lg.bg-white{
  background: #111110c2;
  color: white!important;
  backdrop-filter: blur(5px);
  border-radius: 8px;
  width: 300px;
}

.inline-block.rounded-lg.bg-white button {
  background: transparent;
  color: white!important;
}


.inline-block.rounded-lg.bg-white button[disabled]{
  background: transparent;
  color: rgb(125, 117, 117)!important;
}

.inline-block.rounded-lg.bg-white button.text-white.bg-cyan-700{
  background-color: #f2c14ec4;
}



/* div.custom-field.date-field{
  background: #343432c2;
  color: white;
  backdrop-filter: blur(5px);
  border-radius: 8px;
} */

/* div.custom-field.date-field .inline-block.rounded-lg.bg-white.p-4.shadow-lg.dark{
  background: #343432c2;
  color: white;
  backdrop-filter: blur(5px);
  border-radius: 8px;
} */

.pointer-events-none{
  display: none;
}

/* 
.login-component .introduction-container {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */



/* div.register-upload-options div.image-preview img label{
  position: absolute;
} */




/* In your global CSS file */
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

.pulse-ring {
  position: absolute;
  right: 10px;
  animation: pulse-ring 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  border-width: 2px;
  border-color: #FFD700;
}



#dateOfBirth{
  margin-top: 10px!important;
  height: 54px!important;
}


.swiper-slide.thumbnail {
  width: unset!important;
}


/* 

 Navbar

*/

/* div.navbar-menu {
  background-color: rgba(0, 0, 0, 0.366);
} */

/* div.navbar-menu {
  background-color: rgb(24 24 24 / 44%);
  border: 1px solid #1f1f1f1f;
} */

div.navbar-menu {
  background-color: rgba(30, 30, 30, 0.159);
  backdrop-filter: blur(15px);
  border: 1px solid #1f1f1f2a;
  border-radius: 12px;
}

div.bg-primary-tint{
  background-color: #0a0a0732;
  backdrop-filter: blur(15px);
  border: 1px solid #ededed40;
  border-radius: 12px;
}

div.navbar-menu h2, div.navbar-menu span{
  color: #F2C14E;
}

div.navbar-menu .invitation-screen .referal-code{
  display: flex;
  padding: 5px 20px;
  border-radius: 30px;
  border: solid 1px #F2C14E;
}

/* div.navbar-menu .invitation-screen .text-sm {

} */

div.navbar-menu  .invitation-screen .referal-code img{
  margin: auto;
  height: 20px;
  padding-right: 10px;
}

div.navbar-menu .invitation-screen, div.navbar-menu a {
  font-family: 'Mabry Trail', sans-serif;
}

div.navbar-menu a {
  background: unset;
  font-size: 20px;
}

.mb-5{
  margin-bottom: 50px;
}

.mb-4{
  margin-bottom: 40px;
}

.mb-3{
  margin-bottom: 30px;
}

.mb-2{
  margin-bottom: 20px!important;
}

.mb-1{
  margin-bottom: 10px;
}

.m-25{
  margin: 25px;
}

div.navbar-menu{
  width: 400px;
}

.m-auto{
  margin: auto;
}

.mx-auto{
  margin: 0px auto;
}

div.error {
  color: red;
}

div.navbar-menu .cursor-pointer.px-4.py-2:hover {
  background-color: #2d2d2d61;
}



div.invitation-screen p.text-sm.invitation-message {
  font-weight: 800;
  color: #ffffffa1;
  width: 80%;
  margin: auto;
}

.referal-code {
  position: relative;
  cursor: pointer;
}

.copy-code-message {
  /* position: absolute;
  width: 300px;
  top: 120%;
  left: 50%; */
  /* transform: translateX(-50%); */
  /* visibility: visible; */
  opacity: 1;
  transition: visibility 0s, opacity 0.5s ease-in-out;

  font-weight: 200;
  font-size: 12px;
  color: #ffffff48;
}

.text-white {
  color: white!important;
}

/* .copy-code-message {
  position: absolute;
  width: 300px;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  color: #F2C14E;
} */

/* .referal-code:hover .copy-code-message {
  visibility: visible;
  opacity: 1;
} */



/* div.delete-icon{
  background: transparent;
  color: transparent;
  width: 25px;
  border-radius: 50px;
  position: absolute;
  right: -10px;
  top: -12px;
} */

.isThumbnailSelected div.delete-icon{
  display: block;
  background: red;
  color: white;
  width: 25px;
  border-radius: 50px;
  position: absolute;
  right: -10px;
  top: -12px;
}

div.delete-icon{
  display: none;
}

.thumbnailContainer {
  padding-top: 15px;
}


div.invitation-screen .countdown-timer {
  font-family: 'Mabry Trail medium';
}


/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
} */

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%); 
  z-index: 1;
  margin-top: 10px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  width: 200px;
  text-align: left;
  padding: 11px;
  background: #181818a3;
  color: #d5d4d4;
  font-size: 15px;
  backdrop-filter: blur(14px);
}



.bg-primary {
  background-color: #3c3c3cb9!important;
}

.bg-secondary{
  background-color: #151515!important;
}


li[data-headlessui-state="active selected"], li[data-headlessui-state="active"]{
  background-color: #303030!important;
  cursor: pointer;
}

/* .bg-secondary:hover{
  background-color: #303030!important;
} */




.imageScroll {
  position: relative;
  width: 65%; /* Set width according to image width */
  height: auto; /* Adjust height as needed */
  margin: 0 auto; /* Center the container */
}

button.slideButton {
  z-index: 10;
}

.imageScroll img {
  position: absolute;
  width: 100%; /* Use full width of container */
  height: auto; /* Maintain aspect ratio */
  top: -10px;
  left: 50%; /* Align horizontally */
  transform: translateX(-50%); /* Center the image horizontally */
  transition: all 0.5s ease;
  overflow: hidden; /* Hide overflow */
  clip: rect(0px, 400px, 0px, 0px); /* Only show initial portion */
  z-index: 2;
  border-radius: 8px;
  height: 200px;
  object-fit: cover;
}

/* Hover effect */
.slideButton:hover ~ .imageScroll img {
  opacity: 1; /* Show the images on hover */
}

.slideButton:hover ~ .imageScroll img.img1 {
  transition-delay: 0.1s; /* Delay for img1 */
  clip: rect(0px, 400px, 200px, 0px); 
}

.slideButton:hover ~ .imageScroll img.img2 {
  transition-delay: 0.3s; /* Delay for img2 */
  clip: rect(0px, 400px, 170px, 0px); /* Show the first 170px */
  height: 170px;
}

.slideButton:hover ~ .imageScroll img.img3 {
  transition-delay: 0.5s; /* Delay for img3 */
  clip: rect(0px, 400px, 120px, 0px); /* Show the first 150px */
  height: 110px;
}


.profile-widget, 
.profile-widget div, 
.profile-widget span, 
.profile-widget img{
  background-color: transparent;
  outline: none;
}
